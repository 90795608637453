import React, { useEffect } from "react";
import backgroundBlur from "../assets/images/backgroundBlur.jpg";
import { useSearchParams } from "react-router-dom";
import config from "../config";

const Mastercard = require(`../assets/images/credit-card/Mastercard.png`);
const Maestro = require(`../assets/images/credit-card/Maestro.png`);
const Visa = require(`../assets/images/credit-card/Visa.png`);
const AmericanExpress = require(`../assets/images/credit-card/AmericanExpress.png`);

const StyledInput = ({
  htmlFor,
  label,
  placeholder,
  pattern,
  style,
  containerStyle,
  onChange,
}) => {
  return (
    <div style={containerStyle}>
      <label style={{ marginBottom: 5 }} htmlFor={htmlFor}>
        {label}
      </label>
      <br />
      <input
        style={{
          height: 62,
          backgroundColor: "#FFFFFF",
          borderRadius: 12,
          fontFamily: "Poppins",
          fontSize: 14,
          textDecorationLine: "none",
          paddingLeft: 8,
          paddingRight: 8,
          width: "100%",
          ...style,
        }}
        placeholder={placeholder}
        type="text"
        id={htmlFor}
        name={htmlFor}
        pattern={pattern}
        onChange={onChange}
      />
    </div>
  );
};

export default () => {
  var payment = new window.PaymentJs();
  var firstname = "";
  var lastname = "";
  var creditcardnumber = "";
  var cvvplaceholder = "";

  const [searchParams, setSearchParams] = useSearchParams();
  const cardParam = searchParams.get("card");
  const isCardRegistered = cardParam === "true";

  if (isCardRegistered) {
    firstname = searchParams.get("firstname");
    lastname = searchParams.get("lastname");
    creditcardnumber = "**** **** **** ****";
    cvvplaceholder = "3 oder 4 Ziffern";
  }

  useEffect(() => {
    document.body.style.backgroundColor = "#ededed";
    const key = config.environment === "development" ? "NiygufV1g1wIcCAqkTI8" : "K3s7NVkEnXH8MUNN0Tb1"
    payment.init(key, "number_div", "cvv_div", function (payment) {
      payment.setNumberStyle({
        border: "none",
        height: 62,
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
        fontFamily: "Poppins !important",
        fontSize: 14,
        textDecorationLine: "none",
        outline: "none",
        paddingLeft: 8,
        paddingRight: 8,
      });
      payment.setNumberPlaceholder(creditcardnumber);
      payment.setCvvStyle({
        border: "none",
        height: 62,
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
        fontFamily: "Poppins !important",
        fontSize: 14,
        textDecorationLine: "none",
        outline: "none",
        paddingLeft: 8,
        paddingRight: 8,
        width: 120,
      });
      payment.setCvvPlaceholder(cvvplaceholder);
      payment.numberOn("input", function (data) {
        // alert('A number was entered');
      });
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      var data = {
        first_name: document.getElementById("first_name").value,
        last_name: document.getElementById("last_name").value,
        month: document.getElementById("exp").value.split("/")[0],
        year: "20" + document.getElementById("exp").value.split("/")[1],
      };

      payment.tokenize(
        data,
        function (token, cardData) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ success: true, token: token })
          );
        },
        function (errors) {
          console.log(errors);
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ success: false, errors: errors })
          );
        }
      );
    } catch (e) {
      console.warn(e);
      alert("Leider ist etwas schiefgelaufen.");
    }
  };

  const css = `
  input::placeholder{
    color: #838D95;
    font-size:14px;
  }
`;

  const formatValidDate = (event) => {
    if (event.target.value.length == 3 && event.target.value.includes("/")) {
      event.target.value = event.target.value.slice(0, -1);
      return;
    }

    if (event.target.value.length == 2 && !event.target.value.includes("/")) {
      event.target.value += "/";
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${backgroundBlur})`,
        fontFamily: "Poppins",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <style>{css}</style>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "32px",
          paddingRight: "32px",
        }}
        id="payment_form"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="transaction_token" id="transaction_token" />
        <div style={{marginTop: "16px "}}></div>
        <StyledInput
          htmlFor="first_name"
          label="Vorname"
          style={{ width: "100%" }}
          placeholder={firstname}
        />
        <br />

        <StyledInput
          htmlFor="last_name"
          label="Nachname"
          style={{ width: "100%" }}
          containerStyle={{ marginTop: -20 }}
          placeholder={lastname}
        />
        <label
          style={{ marginBottom: 5, marginTop: 8, alignSelf: "flex-start" }}
          htmlFor="number_div"
        >
          Kreditkartennummer
        </label>
        <div
          id="number_div"
          placeholder="Kreditkartennummer"
          style={{
            fontFamily: "Poppins",
            height: 62,
            marginBottom: 8,
            marginBottom: 8,
          }}
        ></div>
        <div
          style={{
            marginBottom: 24,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div style={{ width: 120, minWidth: 120, marginRight: 8 }}>
            <label style={{ marginBottom: 5 }} htmlFor="cvv_div">
              CVV
            </label>
            <div
              id="cvv_div"
              style={{ fontFamily: "Poppins", height: 62 }}
            ></div>
          </div>
          <StyledInput
            style={{ minWidth: 120, width: 120, height: 62 }}
            htmlFor="exp"
            label="Gültig bis"
            placeholder="MM/JJ"
            pattern="^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$"
            onChange={(event) => formatValidDate(event)}
          />
        </div>

        <input
          style={{
            height: 60,
            backgroundColor: "#7D7AFF",
            paddingVertical: 14,
            paddingHorizontal: 12,
            borderRadius: 12,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
          }}
          type="submit"
          value={"Hinterlegen"}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 40,
            width: "100%",
          }}
        >
          <img src={Mastercard} height={50} />
          <img src={Maestro} height={50} />
          <img src={Visa} height={30} />
          <img src={AmericanExpress} height={50} />
        </div>
      </form>
    </div>
  );
};
