import { ComponentType, h } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { useLocales } from '~core/localisation'
import { GlobalActions } from '~types/redux'
import { StepComponentProps } from '~types/routers'
import style from './RestrictedDocumentSelection.scss'
import {
  setGenericDocumentType,
  setIdDocumentIssuingCountry,
  setIdDocumentType,
} from '../ReduxAppWrapper/store/actions/globals'

import ScreenLayout from '../Theme/ScreenLayout'
import PageTitle from '../PageTitle'

import {
  CountryDropdown,
  HandleCountrySelect,
  SuggestedCountries,
} from '../CountrySelector/CountryDropdown'
import {
  DocumentList,
  HandleDocumentSelect,
} from '../DocumentSelector/DocumentList'

import {
  idDocumentOptions,
  generateDefaultOptions,
  generateCustomOptions,
} from '../DocumentSelector'

import {
  getSupportedCountries,
  getSupportedDocumentTypes,
} from '~supported-documents'

import { CountryData, documentSelectionType } from '~types/commons'
import { trackComponent } from '../../Tracker'
import { WithTrackingProps } from '~types/hocs'
import { buildStepFinder } from '~utils/steps'
import {
  DocumentTypeConfig,
  DocumentTypes,
  StepOptionDocumentType,
} from '~types/steps'

export type RestrictedDocumentSelectionProps = {
  documentSelection?: documentSelectionType[]
  countryFilter?: documentSelectionType[]
} & StepComponentProps &
  WithTrackingProps

export const RestrictedDocumentSelection = trackComponent(
  ({
    nextStep,
    documentSelection,
    countryFilter,
    steps,
  }: RestrictedDocumentSelectionProps) => {
    const { translate, parseTranslatedTags } = useLocales()
    const dispatch = useDispatch<Dispatch<GlobalActions>>()
    const [country, setCountry] = useState<CountryData | undefined>(undefined)

    const options = buildStepFinder(steps)('document')?.options
    const hideCountrySelection = !!options?.hideCountrySelection
    const genericDocumentTypes = options?.genericDocumentTypes || []

    const documentTypeFilter = useMemo(
      () =>
        documentSelection &&
        documentSelection.filter((value, index, self) => {
          return (
            self.findIndex(
              (v) =>
                v.document_type === value.document_type &&
                v.issuing_country === country?.country_alpha3
            ) === index
          )
        }),
      [country]
    )

    const countries = useMemo(() => getSupportedCountries(countryFilter), [])
    const isSingleCountry = useMemo(() => countries.length === 1, [countries])

    useEffect(() => {
      if (isSingleCountry) {
        handleCountrySelect(countries[0])
      }
    }, [isSingleCountry])

    const documents = useMemo(() => {
      if (hideCountrySelection) {
        const filter = options.documentTypes
          ? createFilterFromDocTypes(options.documentTypes)
          : createFilterFromDocTypes({
              passport: true,
              driving_licence: true,
              national_identity_card: true,
              residence_permit: true,
            })
        return generateDefaultOptions(idDocumentOptions, translate, filter)
      }
      if (!country) {
        return []
      }

      const supportedDocumentTypes = getSupportedDocumentTypes(
        country.country_alpha3
      )

      const defaultDocumentOptions = generateDefaultOptions(
        idDocumentOptions,
        translate,
        documentTypeFilter
      )

      const documentOptions = defaultDocumentOptions.filter(({ type }) =>
        supportedDocumentTypes.some((supportedType) => supportedType === type)
      )

      const genericDocumentOptions = generateCustomOptions(
        genericDocumentTypes,
        country.country_alpha3
      )

      return [...documentOptions, ...genericDocumentOptions]
    }, [
      country,
      translate,
      hideCountrySelection,
      documentTypeFilter,
      options?.documentTypes,
    ])

    const handleCountrySelect: HandleCountrySelect = (selectedCountry) => {
      if (!selectedCountry) {
        return
      }
      dispatch(setIdDocumentIssuingCountry(selectedCountry))
      setCountry(selectedCountry)
    }

    const implicitelySetCountryFromConfig = (type: DocumentTypes) => {
      // because we hide the country selection, if the client set a country for the document option we should dispatch it anyway.
      const docType = options?.documentTypes?.[type] as DocumentTypeConfig
      if (!docType || typeof docType === 'boolean') {
        return
      }

      if (!docType.country) {
        return
      }

      const implicitCountryFromDocType = countries.find(
        (c) => c.country_alpha3 === docType.country
      )

      if (implicitCountryFromDocType) {
        dispatch(setIdDocumentIssuingCountry(implicitCountryFromDocType))
      }
    }

    const handleDocumentSelect: HandleDocumentSelect = ({ type, id }) => {
      if (hideCountrySelection) {
        implicitelySetCountryFromConfig(type)
      }
      dispatch(setIdDocumentType(type))

      const genericDocumentType = genericDocumentTypes.find(
        (documentType) => documentType.id === id
      )
      if (genericDocumentType) {
        dispatch(setGenericDocumentType(genericDocumentType))
      }

      nextStep()
    }

    const suggestCountries: SuggestedCountries = (
      query = '',
      populateResults
    ) => {
      if (country && query !== country.name) {
        setCountry(undefined)
      }

      populateResults(
        countries.filter((filteredCountry) =>
          filteredCountry.name
            .toLowerCase()
            .includes(query.trim().toLowerCase())
        )
      )
    }

    const subtitle =
      hideCountrySelection || isSingleCountry
        ? undefined
        : translate('doc_select.subtitle_country')

    return (
      <ScreenLayout pageId="restrictedDocumentSelection">
        <PageTitle title={translate('doc_select.title')} subTitle={subtitle} />
        {!hideCountrySelection && (
          <div className={style.selectionContainer}>
            <label htmlFor="country-search">
              {translate('doc_select.section.header_country')}
            </label>
            {isSingleCountry && (
              <div
                className={style.singleCountryInputWrapper}
                data-onfido-qa="countrySelector"
              >
                <input
                  className={`ods-input ${style.disabledInput}`}
                  disabled
                  value={country?.name}
                  type="text"
                />
              </div>
            )}
            {!isSingleCountry && (
              <CountryDropdown
                suggestCountries={suggestCountries}
                handleCountrySelect={handleCountrySelect}
                placeholder={translate(
                  'doc_select.section.input_placeholder_country'
                )}
                noResults={translate(
                  'doc_select.section.input_country_not_found'
                )}
              />
            )}
          </div>
        )}
        {documents.length > 0 ? (
          <div
            className={style.selectionContainer}
            data-onfido-qa="documentSelector"
          >
            <label>{translate('doc_select.section.header_doc_type')}</label>
            <DocumentList
              options={documents}
              handleDocumentSelect={handleDocumentSelect}
              parseTranslatedTags={parseTranslatedTags}
              translate={translate}
            />
          </div>
        ) : undefined}
      </ScreenLayout>
    )
  },
  'type_select'
) as ComponentType<StepComponentProps>

const createFilterFromDocTypes = (
  documentTypes: StepOptionDocumentType
): documentSelectionType[] => {
  const result: documentSelectionType[] = []
  const docTypeKeys = Object.keys(documentTypes) as Array<DocumentTypes>
  docTypeKeys.map((key) => {
    if (documentTypes[key]) {
      result.push({
        document_type: key,
        id: '',
        issuing_country: '',
        config: '',
      })
    }
  })
  return result
}
