const environment = process.env.NODE_ENV;

const config = {
  ApiUrl: "",
  WebUrl: "",
  environment: "",
  firebase: ""
};

if (environment === "development") {
  config.ApiUrl = "https://shary-backend.shadet.de/api";
  config.environment = "development";
  config.firebase = {
    apiKey: 'AIzaSyCTV3_gZVKkp1ExfnoNpTcuTP7uybostXQ',
    authDomain: 'cardome-dccdd.firebaseapp.com',
    projectId: 'cardome-dccdd',
    storageBucket: 'cardome-dccdd.appspot.com',
    messagingSenderId: '1046779766417',
    appId: '1:1046779766417:web:5f70704e794c0c2c2e8130',
    databaseURL: 'https://cardome-dccdd-default-rtdb.europe-west1.firebasedatabase.app',
    measurementId: 'G-832XWK6GTN',
  };
} else if (environment === "production") {
  config.ApiUrl = "https://api.theshary.de/api";
  config.environment = "production";
  config.firebase = {
    apiKey: "AIzaSyBzAFpOm7cXjZUkCc_zGAESbkxehQHzesA",
    authDomain: "shary-85c69.firebaseapp.com",
    databaseURL: "https://shary-85c69-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "shary-85c69",
    storageBucket: "shary-85c69.appspot.com",
    messagingSenderId: "20961651536",
    appId: "1:20961651536:web:387d7d6f5c2e873782da55",
    measurementId: "G-PWX00NCQTE"
  };
} else {
  console.error("NODE_ENV is wrong, It is neither development or production.");
}

export default config;
